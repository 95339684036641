<template>
  <div class="collapse-item">
    <div class="collapse-title">
      <div class="collapse-title-icon" @click="expandChange">
        <MinusSquareFilled v-if="expand" />
        <PlusSquareFilled v-else />
      </div>
      <div class="collapse-title-text">
        <span v-if="title">{{ title }}</span>
        <slot v-else name="title"> </slot>
      </div>
      <!-- <div class="collapse-title-right"></div> -->
    </div>
    <div v-if="expand" class="collapse-content">
      <slot> </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { PlusSquareFilled, MinusSquareFilled } from '@ant-design/icons-vue'
export default defineComponent(
  {
    name: 'e-collapse-item',
    components: {
      PlusSquareFilled,
      MinusSquareFilled
    },
    props: {
      title: String,
      isExpand: {
        type: Boolean,
        require: true,
        default: false
      }
    },
    setup (props) {
      const state = reactive(
        {
          expand: props.isExpand as boolean
        }
      )
      const expandChange = () => {
        state.expand = !state.expand
      }
      return {
        ...toRefs(state),
        expandChange
      }
    }
  }
)
</script>

<style scoped>
.collapse-title {
  padding: 10px 24px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
}
.collapse-title-icon {
  display: flex;
  align-items: center;
}
.collapse-title-text {
  margin: 0 16px;
  flex: 1;
}
.collapse-item {
  margin-bottom: 16px;
}
</style>
